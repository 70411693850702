// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-hero-page-tsx": () => import("../src/templates/hero-page.tsx" /* webpackChunkName: "component---src-templates-hero-page-tsx" */),
  "component---src-templates-gallery-page-tsx": () => import("../src/templates/gallery-page.tsx" /* webpackChunkName: "component---src-templates-gallery-page-tsx" */),
  "component---src-templates-content-page-tsx": () => import("../src/templates/content-page.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-schedule-page-tsx": () => import("../src/templates/schedule-page.tsx" /* webpackChunkName: "component---src-templates-schedule-page-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-galerien-index-tsx": () => import("../src/pages/galerien/index.tsx" /* webpackChunkName: "component---src-pages-galerien-index-tsx" */)
}

